import React, {useCallback, useEffect, useState} from 'react';
import './Story.css';
import './Boost.css';

import useEmblaCarousel from 'embla-carousel-react'
import {EmblaCarouselType, EmblaEventType} from 'embla-carousel'
import {haptic, id, iOS, is_premium, referralCopyLink, tg, url, username} from "../pages/Go";

import coffee from '../assets/stories/coffee.png';
import enjoy from '../assets/stories/enjoy.png';
import chew from '../assets/stories/chew.png';
import history from '../assets/stories/history.png';
import axios from "axios";

import ton_img from '../assets/ton.svg';
import xtr_img from '../assets/xtr.svg';
import wallet_img from '../assets/wallet.svg';
import hi from "../assets/hi_illust.svg";
import {TonConnectButton, useTonAddress, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";


const Boost = ({boostModal, setBoostModal, setBalance, power, authRequest, userWallet}) => {


    const [notification, setNotification] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState(1); // 0 -- neutral, 1 -- success, 2 -- error
    const [notificationParagraph, setNotificationParagraph] = useState('')

    useEffect(() => {

        if (!notification) {
            setTimeout(() => {
                setNotificationParagraph('');
            }, 1500)
        }

    }, [notification])


    const date = Math.floor(Date.now() / 1000);



    const [currencyToggle, setCurrencyToggle] = useState('XTR');

    const [boostRates, setBoostRates] = useState([]);



    const getBoostRatesRequest = () => {

        axios.post(`${url}/api/get_boost_rates`, {
            data: tg.initData,
        })
            .then(response => {

                setBoostRates(response.data?.sort((a, b) => a.id - b.id));

            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        getBoostRatesRequest();
    }, [])





    const createInvoiceLinkRequest = async (boost_id) => {

        axios.post(`${url}/api/create_invoice_link`, {
            boost_id: boost_id,
            data: tg.initData,
        })
            .then(response => {

                tg.openInvoice(response.data);

            })
            .catch(error => {
                // alert(error);
            });

    }

    tg.onEvent('invoiceClosed', async (event) => {
        await authRequest();
        if (event.status === 'paid') {
            setNotification(true);
            setNotificationStatus(1);
        } else if (event.status === 'failed') {
            setNotification(true);
            setNotificationStatus(2);
        }
    })






    const [tonConnectUI, setOptions] = useTonConnectUI();
    const wallet = useTonWallet();

    const userFriendlyAddress = useTonAddress();
    const rawAddress = useTonAddress(false);
    console.log(rawAddress);


    const connectWalletRequest = async () => {

        axios.post(`${url}/api/connect_wallet`, {
            wallet: rawAddress,
            data: tg.initData,
        })
            .then(response => {

                if (response.data == 2) {
                    tonConnectUI.disconnect();
                    setNotification(true);
                    setNotificationStatus(2);
                    setNotificationParagraph('wallet taken');
                }

            })
            .catch(error => {
                // alert(error);
            });

    }

    useEffect(() => {
        if (rawAddress.length > 5) {
            connectWalletRequest();
        }
    }, [rawAddress])


    const disconnectWalletRequest = async () => {

        axios.post(`${url}/api/disconnect_wallet`, {
            data: tg.initData,
        })
            .then(async response => {


            })
            .catch(error => {
                // console.log(error);
            });

    }



    const getWalletTransactionsRequest = async () => {

        axios.post(`${url}/api/get_wallet_transactions`, {
            data: tg.initData,
        })
            .then(async response => {

                if (response.data === 1) {

                    await authRequest();

                    setNotification(true);
                    setNotificationStatus(1);

                } else if (response.data === 2) {

                    setNotification(true);
                    setNotificationStatus(2);

                }

            })
            .catch(error => {
                // console.log(error);
            });

    }

    const [transactionsRequestFrequency, setTransactionsRequestFrequency] = useState(7000);
    useEffect(() => {

        const interval = setInterval(() => {

            if (wallet) {
                getWalletTransactionsRequest();
            }

        }, transactionsRequestFrequency);

        return () => clearInterval(interval);

    }, [rawAddress])




    const createTransaction = (ton) => {
        return {
            validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
            messages: [
                {
                    address: "UQBhXEBOUWogHYvUoQdSTDeUZV5cR5fgxr9LOmKCjXRmTuim", // message destination in user-friendly format 0:615c404e516a201d8bd4a107524c3794655e5c4797e0c6bf4b3a62828d74664e
                    amount: ton * 1000000000, // toncoin in nanotons
                },
            ],
        };
    }



    return (

        <>

            <div className={`${notification ? 'active' : ''} notification-wrapper`}>

                <div
                    className={`${notification ? 'active' : ''} notification-overlay`}
                    onClick={() => setNotification(false)}
                >

                </div>

                <div className={`${notification ? 'active' : ''} notification-container`}>

                    <div className={'notification-container-top'}>
                        <h2 className={'purple'}>
                            {
                                notificationStatus === 1 ?
                                    'SUCCESS!'
                                    :
                                    'FAILED!'
                            }
                        </h2>

                        <p className={'white'}>
                            {
                                notificationStatus === 1 ?
                                    <>
                                        You successfully bought GO Boost!
                                        <br/>
                                        GO Power increased to <span className={'purple'}>{power}.</span>
                                    </>
                                    :
                                    notificationParagraph === '' ?
                                        <>
                                            Something went wrong..
                                            <br/>
                                            <span className={'purple'}>Please try again later.</span>
                                        </>
                                        :
                                        <>
                                            This wallet already taken..
                                            <br/>
                                            <span className={'purple'}>Please try another one.</span>
                                        </>
                            }
                        </p>

                    </div>

                    {/*<div className={'tasks-img-container'}>
                        <img className={'tasks-img'} src={hi}/>
                    </div>*/}

                    <button
                        className={`btn full purple`}
                        onClick={() => {
                            setNotification(false);
                            haptic.impactOccurred('light');
                        }}
                    >
                        GO AHEAD
                    </button>

                </div>

            </div>


            <div className={`${boostModal ? 'active' : ''} modal-overlay`} onClick={() => setBoostModal(false)}></div>
            <div className={`${boostModal ? 'active' : ''} autoheight tasks-wrapper modal-wrapper`}>


                <div className={'story-container-top'}>

                    <div className={'header-left'}>
                        <h3 className={''}>
                            GO BOOST
                        </h3>
                        <div className={'header-left-bottom'}>
                            <p className={'white'}>
                                Increase your GO Power!
                            </p>
                        </div>
                    </div>

                </div>


                {
                    // iOS() ?
                    0 > 1 ?
                        ''
                        :
                        <>


                            <div
                                className={`${wallet ? 'white' : 'graylight'} task-item`}
                                style={{marginTop: '12px', marginBottom: '20px'}}
                                onClick={() => {
                                    tonConnectUI.openModal();
                                }}
                            >


                                <div className={`purple rank-friends-container-index black`} style={{background: 'var(--purple)'}}>
                                    <img src={wallet_img}/>
                                </div>


                                <div className={'rank-friends-container-right nopr'}>
                                    <div className={'task-item-left'}>

                                        <h3 className={'bold black'} style={{textTransform: 'uppercase'}}>
                                            WALLET
                                        </h3>
                                        <p className={'black'} style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '135px'}}>
                                            {
                                                wallet ?
                                                    userFriendlyAddress
                                                    :
                                                    'Click to Connect'
                                            }
                                        </p>
                                    </div>

                                </div>


                                {
                                    wallet ?
                                        <button
                                            className={`btn flexible small black`}
                                            onClick={() => {
                                                tonConnectUI.disconnect();
                                                disconnectWalletRequest();
                                            }}
                                        >
                                            Disconnect
                                        </button>
                                        :
                                        ''
                                }


                            </div>


                            <div className={'toggle graylight'} style={{width: '100%'}}>

                                <button
                                    className={`full btn ${currencyToggle === 'XTR' ? 'white z10' : 'graylight'}`}
                                    onClick={() => setCurrencyToggle('XTR')}
                                >
                                    STARS
                                </button>

                                <button
                                    className={`full btn ${currencyToggle === 'TON' ? 'white z10' : 'graylight'}`}
                                    onClick={() => setCurrencyToggle('TON')}
                                >
                                    TON
                                </button>

                            </div>

                        </>
                }


                <div className={'boost-container'}>

                    {
                        boostRates.map((boost, boostIndex) =>

                            <div
                                className={'boost-item'}
                                key={boostIndex}
                                onClick={() => {
                                    if (currencyToggle === 'XTR') {
                                        createInvoiceLinkRequest(boost.id);
                                    } else if (wallet) {
                                        tonConnectUI.sendTransaction(createTransaction(boost.ton));
                                    } else {
                                        tonConnectUI.openModal()
                                            .then(async () => {
                                                if (wallet) {
                                                    await tonConnectUI.sendTransaction(createTransaction(boost.ton))
                                                }
                                            });
                                    }
                                }}
                            >

                                <div className={'task-item'} key={boostIndex}>


                                    <div className={`purple rank-friends-container-index black`}>
                                        <img src={currencyToggle === 'XTR' ? xtr_img : ton_img}/>
                                    </div>


                                    <div className={'rank-friends-container-right nopr'}>
                                        <div className={'task-item-left'}>

                                            <h3 className={'bold black'} style={{textTransform: 'uppercase'}}>
                                                +{boost.go} GO Power
                                            </h3>
                                            <p className={'black'}>
                                                {currencyToggle === 'XTR' ? boost.xtr : boost.ton} {currencyToggle}
                                            </p>
                                        </div>

                                    </div>


                                    {
                                        boost.id === 3 || boost.id === 4 ?
                                            <button className={'btn flexible small black'}>
                                                {
                                                    boost.id === 3 ?
                                                        'Popular'
                                                        :
                                                        'Top Deal'
                                                }
                                            </button>
                                            :
                                            ''
                                    }


                                </div>

                            </div>
                        )
                    }

                </div>

                <p className={'white'} style={{padding: '18px 0'}}>
                    When purchasing you agree to <a className={'purple'}>Terms of Use.</a>
                </p>


                <button
                    className={'full btn noshadow white'}
                    onClick={() => {
                        setBoostModal(false);
                        haptic.impactOccurred('light');
                    }}
                >
                    CLOSE
                </button>


            </div>


        </>


    );
};

export default Boost;