import React, {useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import './Rank.css';
import coin from '../assets/coin_dark.svg';
import {convertNumberToShortString, first_name, haptic, username} from "./Go";

const Rank = ({active, topOffset, rankGoArray, rankFriendsArray, level, balance, power, referrals_count, go_from_referrals}) => {


    const [rankToggle, setRankToggle] = useState(true);



    return (
        <AnimatePresence>
            {active && (
                <motion.div
                    className={"main-wrapper rank-wrapper"}
                    key="rank"
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    style={{
                        opacity: active ? 1 : 0,
                        filter: active ? 'brightness(100%)' : 'brightness(0%)',
                        paddingTop: `calc(${topOffset}px)`,
                        // display: active ? 'flex' : 'none',
                    }}
                >

                    <div className={'rank-header'}>
                        <h1 className={'purple'}>
                            RANK
                        </h1>

                        <p className={'white'}>
                            Invite Friends, grow Network and increase GO Power!
                        </p>
                    </div>


                    <div
                        className={'rank-container-big'}
                        style={{
                            marginTop: `calc(${topOffset}px)`
                        }}
                    >

                        <div className={'toggle full gray'}>

                            <button
                                className={`full btn ${rankToggle ? 'white z10' : 'gray z1'}`}
                                onClick={() => {
                                    setRankToggle(true);
                                    haptic.impactOccurred('light');
                                }}
                            >
                                $TONGO
                            </button>

                            <button
                                className={`full btn ${!rankToggle ? 'white z10' : 'gray z1'}`}
                                onClick={() => {
                                    setRankToggle(false);
                                    haptic.impactOccurred('light');
                                }}
                            >
                                FRIENDS
                            </button>
                        </div>

                        <div className={'rank-container nobg'}>

                            <h3 className={'rank-friends-h3 white'}>
                                MY RANK
                            </h3>

                            <div className={'rank-friends-container'}>

                                <h2 className={`purple rank-friends-container-index ${rankGoArray.indexOf(rankGoArray.find((i) => i.username == username)) < 98 ? '' : 'huna'}`}>
                                    {
                                        rankGoArray.indexOf(rankGoArray.find((i) => i.username == username)) < 100 ?
                                            rankGoArray.indexOf(rankGoArray.find((i) => i.username == username)) < 9 ?
                                                '0' + (rankGoArray.indexOf(rankGoArray.find((i) => i.username == username)) + 1)
                                                :
                                                rankGoArray.indexOf(rankGoArray.find((i) => i.username == username)) + 1
                                            :
                                            '99+'
                                    }
                                </h2>

                                <div className={'rank-friends-container-right'} style={{paddingRight: '12px', marginTop: '5px'}}>
                                    <div className={'rank-friend-left'}>
                                        <p className={'bold white'}>
                                            {first_name || ''}
                                        </p>
                                        {/*<p className={'white'}>
                                            Level: {level}
                                        </p>*/}
                                    </div>

                                    <div className={'rank-friend-left right'}>
                                        <p className={'purple bold'}>
                                            {rankToggle ? 'GO' : 'FRIENDS'}: {convertNumberToShortString(rankToggle ? balance : referrals_count)}
                                        </p>
                                        {/*<p className={'white'}>
                                            {rankToggle ? 'GO Power' : 'GO from Friends'}: {convertNumberToShortString(rankToggle ? power : go_from_referrals)}
                                        </p>*/}
                                    </div>
                                </div>

                            </div>

                            <h3 className={'rank-friends-h3 white'}>
                                TOP 100
                            </h3>

                            {
                                (rankToggle ? rankGoArray : rankFriendsArray)?.map((r, i) =>
                                    <div className={'rank-friends-container'} key={i}>

                                        <h2 className={`purple rank-friends-container-index ${i >= 99 ? 'huna' : ''}`}>
                                            {
                                                i < 9 ?
                                                    '0' + (i + 1)
                                                    :
                                                    i + 1
                                            }
                                        </h2>

                                        <div className={'rank-friends-container-right'} style={{paddingRight: '12px', marginTop: '5px'}}>
                                            <div className={'rank-friend-left'}>
                                                <p className={'bold white'}>
                                                    {r.name || ''}
                                                </p>
                                                {/*<p className={'white'}>
                                                    Level: {r.level}
                                                </p>*/}
                                            </div>

                                            <div className={'rank-friend-left right'}>
                                                <p className={'purple bold'}>
                                                    {rankToggle ? 'GO' : 'FRIENDS'}: {convertNumberToShortString(rankToggle ? r.balance : r.referrals_count)}
                                                </p>
                                                {/*<p className={'white'}>
                                                    {rankToggle ? 'GO Power' : 'GO from Friends'}: {convertNumberToShortString(rankToggle ? r.power : r.go_from_referrals)}
                                                </p>*/}
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                        </div>

                        <div className={'rank-mb-dummie'}>
                        &nbsp;
                        </div>

                        {/*<div
                            className={'invite-wrapper'}
                            // style={{marginBottom: `calc(${topOffset}px - var(--headerheight) - var(--blockpadding))`}}
                        >

                            <button className={'invite-share-container btn purple'} style={{width: '70vw'}}>
                                INVITE FRIENDS
                            </button>

                            <button className={'invite-share-container btn gray'}>
                                COPY LINK
                            </button>

                        </div>*/}

                    </div>


                </motion.div>
            )}
        </AnimatePresence>
    )

};

export default Rank;