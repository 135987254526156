import React, {useCallback, useEffect, useState} from 'react';
import './Story.css';

import useEmblaCarousel from 'embla-carousel-react'
import {EmblaCarouselType, EmblaEventType} from 'embla-carousel'
import {haptic, id, is_premium, referralCopyLink, tg, url, username} from "../pages/Go";

import coffee from '../assets/stories/coffee.png';
import enjoy from '../assets/stories/enjoy.png';
import chew from '../assets/stories/chew.png';
import history from '../assets/stories/history.png';
import axios from "axios";


const Story = ({storyModal, setStoryModal, setBalance, power}) => {


    const date = Math.floor(Date.now() / 1000);


    const [swipeClue, setSwipeClue] = useState(true);

    const [emblaRef, emblaApi] = useEmblaCarousel()

    const logSlidesInView = useCallback((emblaApi) => {
        setSwipeClue(false);
    }, [])

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
    }, [emblaApi])

    const scrollToStart = useCallback(() => {
        if (emblaApi) emblaApi.scrollTo(0, true);
    }, [emblaApi])

    useEffect(() => {
        if (emblaApi) emblaApi.on('scroll', logSlidesInView)
    }, [emblaApi, logSlidesInView])


    const [storiesStatsArray, setStoriesStatsArray] = useState([]);
    const [storiesArray, setStoriesArray] = useState([]);
    const [storiesStatus, setStoriesStatus] = useState(0);

    let stories = [
        {
            id: 1,
            img: chew,
            posted_count: storiesStatsArray.find((item) => item.story_id === 1)?.posted_count,
            posted: storiesArray?.find(story => story.id === 1) !== undefined,
            date: storiesArray?.find(story => story.id === 1)?.date || 0,
            reward: storiesArray?.find(story => story.id === 1)?.reward || power,
            claimed: storiesArray?.find(story => story.id === 1)?.claimed || 0,
        },
        {
            id: 2,
            img: enjoy,
            posted_count: storiesStatsArray.find((item) => item.story_id === 2)?.posted_count,
            posted: storiesArray?.find(story => story.id === 2) !== undefined,
            date: storiesArray?.find(story => story.id === 2)?.date || 0,
            reward: storiesArray?.find(story => story.id === 2)?.reward || power,
            claimed: storiesArray?.find(story => story.id === 2)?.claimed || 0,
        },
        {
            id: 3,
            img: coffee,
            posted_count: storiesStatsArray.find((item) => item.story_id === 3)?.posted_count,
            posted: storiesArray?.find(story => story.id === 3) !== undefined,
            date: storiesArray?.find(story => story.id === 3)?.date || 0,
            reward: storiesArray?.find(story => story.id === 3)?.reward || power,
            claimed: storiesArray?.find(story => story.id === 3)?.claimed || 0,
        },
        {
            id: 24,
            img: history,
            posted_count: storiesStatsArray.find((item) => item.story_id === 24)?.posted_count,
            posted: storiesArray?.find(story => story.id === 24) !== undefined,
            date: storiesArray?.find(story => story.id === 24)?.date || 0,
            reward: storiesArray?.find(story => story.id === 24)?.reward || power,
            claimed: storiesArray?.find(story => story.id === 24)?.claimed || 0,
        },
    ]


    const [storiesRequestFrequency, setStoriesRequestFrequency] = useState(10000);

    const getStoriesRequest = () => {

        axios.post(`${url}/api/get_stories`, {
            id: id,
            username: username,
            data: tg.initData,
        })
            .then(response => {

                setStoriesArray(response.data[0]);
                setStoriesStatus(response.data[1]);

                if (response.data[1] === 2 ||response.data[1] === 3) {
                    setStoriesRequestFrequency(12000);
                }

                if (newToggle && response.data[0].length === 0) {
                    setNewToggle(false);
                }

            })
            .catch(error => {
                console.log(error);
            });
    };


    const claimStoriesRequest = (story_id) => {

        axios.post(`${url}/api/claim_story`, {
            id: id,
            username: username,
            story_id: story_id,
            data: tg.initData,
        })
            .then(response => {

                /*const newStories = [...stories];

                newStories[stories.indexOf(stories.find(story => story.id === story_id))] = response.data;

                setStoriesArray(newStories);
                console.log(newStories);*/

                setBalance(+response.data);


                getStoriesStatsRequest();


            })
            .catch(error => {
                console.log(error);
            });
    };





    const getStoriesStatsRequest = () => {

        axios.post(`${url}/api/get_stories_stats`, {
            data: tg.initData,
        })
            .then(response => {

                setStoriesStatsArray(response.data);


                tg.ready();

                getStoriesRequest();

            })
            .catch(error => {
                console.log(error);
            });
    };



    useEffect(() => {

        const interval = setInterval(() => {

            getStoriesStatsRequest();

        }, storiesRequestFrequency);

        return () => clearInterval(interval);

    }, [storiesArray])

    useEffect(() => {

        getStoriesStatsRequest();

    }, [])


    const processPostStory = (story) => {
        tg.shareToStory(
            story.img,
            {
                text: `Join me at TonGO ${referralCopyLink} #TONGO #GOSTORY${story.id}`,
                /*widget_link:
                    {
                        name: 'JOIN IN',
                        url: referralCopyLink
                    }*/
            }
        );
        setStoriesRequestFrequency(5000);
    }



    const [newToggle, setNewToggle] = useState(false);

    useEffect(() => {

        scrollToStart();

    }, [newToggle]);



    return (


        <div className={`${storyModal ? 'active' : ''} story-wrapper modal-wrapper`}>

            <div className={'story-container-top'}>


                <div className={'header-left'}>
                    <h3 className={''}>
                        GO STORY
                    </h3>
                    <div className={'header-left-bottom'}>
                        <p className={'white'}>
                            Swipe and Post!
                        </p>
                    </div>
                </div>

                <div className={'toggle graylight'}>

                    <button
                        className={`flexible btn ${newToggle ? 'white' : 'graylight'} ${storiesArray.length === 0 ? 'disabled' : ''}`}
                        onClick={() => setNewToggle(true)}
                    >
                        POSTED {storiesArray.length === 0 ? '' : storiesArray.length}
                    </button>

                    <button
                        className={`flexible btn ${!newToggle ? 'white' : 'graylight'}`}
                        onClick={() => setNewToggle(false)}
                    >
                        NEW
                    </button>

                </div>


                {/*<h3 className={'white'}>
                    GO STORY
                </h3>

                <h3 className={`graylight`}>
                    {swipeClue ? 'SWIPE' : ''}
                </h3>*/}

            </div>

            <div className={'embla'} ref={emblaRef}>
                <div className={'embla__container story-container'}>

                    {
                        stories.filter(story => newToggle ? story.posted : !story.posted).map((story, storyIndex) =>
                            <div
                                className={'embla__slide story-item'}
                                key={storyIndex}
                            >

                                <div style={{
                                    display: story.posted ? 'flex' : 'none',
                                    flexDirection: 'column',
                                    width: '100%',
                                    marginBottom: '12px',
                                }}>
                                    <div className={'story progress-bar-container'}
                                         style={{background: 'var(--graylight)'}}>
                                        <div className={'progress-bar-active'}
                                             style={{width: `${(date - story.date) / 86400 * 100}%`}}>

                                        </div>
                                        <div className={'progress-bar-remaining'}
                                             style={{width: `${(100 - (((date - story.date) / 86400) * 100))}%`}}
                                        >
                                        </div>
                                    </div>

                                    <div className={'story-item-center-item top'}>
                                        <h2 className={'white'}>
                                            CLAIMED
                                        </h2>
                                        <h2 className={' purple'}>
                                            {story.claimed}<span className={'white'}>GO</span>
                                            {/*<span className={'white'}>GO</span>*/}
                                        </h2>
                                    </div>
                                </div>

                                <div className={`${story.posted ? 'posted' : ''} story-item-top`}>
                                    <img className={`${story.posted ? 'posted' : ''} story-item-img`}
                                         src={story.img}/>

                                    <div
                                        className={'story-done'}
                                        style={{display: story.posted && story.date + 86400 < date ? 'flex' : 'none'}}
                                    >
                                        <h1 className={'purple'}>
                                            DONE
                                        </h1>
                                    </div>

                                </div>


                                <div className={'story-item-bottom'}>
                                    <button
                                        className={`full btn ${story.posted ? 'empty' : ''} purple`}
                                        onTouchEnd={() => {
                                            story.posted && Math.floor((date - story.date) / 86400 * story.reward) - story.claimed >= 1 ?
                                                claimStoriesRequest(story.id)
                                                :
                                                story.posted ?
                                                    console.log('ALREADY POSTED')
                                                    :
                                                    is_premium ?
                                                        processPostStory(story)
                                                        :
                                                        console.log('UPGRADE TO PREMIUM');

                                            haptic.impactOccurred('light');
                                        }}
                                    >
                                        {
                                            story.posted ?
                                                Math.floor((date - story.date) / 86400 * story.reward) >= 1 ?
                                                    `CLAIM ${story.date + 86400 > date ? '' : 'REST'} ${(story.date + 86400 < date ? story.reward : Math.floor((date - story.date) / 86400 * story.reward)) - story.claimed}`
                                                    :
                                                    'POSTED'
                                                :
                                                is_premium ?
                                                    'POST'
                                                    :
                                                    'UPGRADE TO PREMIUM'
                                        }
                                    </button>
                                </div>


                                <div className={'story-item-center'}>

                                    <div className={'story-item-center-item'}>
                                        <p className={'purple'}>
                                            GO Story {story.id}
                                        </p>
                                        <p className={'white'}>
                                            {(+story.posted_count).toLocaleString(undefined, {
                                                maximumFractionDigits: 0,
                                                minimumFractionDigits: 0
                                            })} Posted
                                        </p>
                                    </div>

                                    <div className={'story-item-center-item'}>
                                        <h2 className={'white'}>
                                            POWER
                                        </h2>
                                        <h2 className={' purple'}>
                                            {story.reward}<span className={'white'}>GO</span>
                                        </h2>
                                    </div>

                                </div>


                            </div>
                        )
                    }

                </div>
            </div>

            <button
                className={'full btn noshadow white'}
                onTouchEnd={() => {
                    setStoryModal(false);
                    haptic.impactOccurred('light');
                }}
            >
                CLOSE
            </button>

        </div>


    );
};

export default Story;