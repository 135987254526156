import React from 'react';
import {username} from "../pages/Go";
import './Header.css';

const Header = () => {
    return (
        <div className={'header-wrapper'}>

            <div className={'header-left'}>
                <h3 className={''}>
                    TONGO IS HERE
                </h3>
                <div className={'header-left-bottom'}>
                    <p className={'white'}>
                        Watch a tutorial!
                    </p>
                </div>
            </div>

            <button className={'flexible btn purple empty'}>
                VIEW
            </button>

        </div>
    );
};

export default Header;