import React, {useEffect, useState} from 'react';
import './Tasks.css';
import axios from "axios";
import {convertNumberToShortString, haptic, id, tg, url} from "../pages/Go";
import hi from '../assets/hi_illust.svg';

const Levels = ({levelsModal, setLevelsModal, referralsCount}) => {




    const levels = [
        {
            referrals: 10,
            power: 360
        },
        {
            referrals: 25,
            power: 480
        },
        {
            referrals: 50,
            power: 720
        },
        {
            referrals: 100,
            power: 960
        },
        {
            referrals: 250,
            power: 1200
        },
        {
            referrals: 500,
            power: 1560
        },
        {
            referrals: 1000,
            power: 1920
        },
        {
            referrals: 2500,
            power: 2400
        },
        {
            referrals: 5000,
            power: 2880
        },
        {
            referrals: 10000,
            power: 3360
        },
    ]




    return (


        <>
            <div className={`${levelsModal ? 'active' : ''} modal-overlay`} onClick={() => setLevelsModal(false)}></div>
            <div className={`${levelsModal ? 'active' : ''} autoheight tasks-wrapper modal-wrapper`}>


                <div className={'story-container-top'}>


                    <div className={'header-left'}>
                        <h3 className={'white'}>
                            LEVELS
                        </h3>
                        <div className={'header-left-bottom'}>
                            <p className={'white'}>
                                Increase GO POWER by inviting Friends
                            </p>
                        </div>
                    </div>

                </div>


                <div className={'tasks-container'} style={{marginTop: '12px', display: 'grid', gridTemplateColumns: 'repeat(2,1fr)'}}>

                    {
                        levels.map((level, index) =>

                            <div className={'task-item'} key={index}>

                                <div className={'task-item-left'}>

                                    <h3 className={'bold black'}>
                                        {convertNumberToShortString(level.referrals)} FRIENDS
                                    </h3>
                                    <p className={'black'}>
                                        {level.power} Base Power
                                    </p>
                                </div>

                                <p className={'bold white'} style={{marginRight: '4px'}}>
                                    {index+1}
                                </p>

                            </div>
                        )
                    }

                </div>

                <button
                    className={'btn full white noshadow'}
                    onTouchEnd={() => {
                        setLevelsModal(false);
                        haptic.impactOccurred('light');
                    }}
                >
                    CLOSE
                </button>

            </div>
        </>


    );
};

export default Levels;