import React, {useEffect, useState} from 'react';
import './Tasks.css';
import axios from "axios";
import {haptic, id, tg, url} from "../pages/Go";
import hi from '../assets/hi_illust.svg';

const Tasks = ({tasksModal, setTasksModal, power, setPower, setTasksAmount, setTasksCompleted}) => {



    const [tasksArray, setTasksArray] = useState([]);
    const [tasksSchemaArray, setTasksSchemaArray] = useState([]);

    const getTasksRequest = () => {

        axios.post(`${url}/api/get_tasks`, {
            id: id,
            data: tg.initData,
        })
            .then(response => {

                setTasksArray(response.data);

                setTasksCompleted(response.data.filter(f => f.claimed)?.length);

            })
            .catch(error => {
                console.log(error);
            });
    };

    const getTasksSchemaRequest = () => {

        axios.post(`${url}/api/get_tasks_schema`, {
            data: tg.initData,
        })
            .then(response => {

                setTasksSchemaArray(response.data);
                setTasksAmount(response.data.length);

                getTasksRequest();


            })
            .catch(error => {
                console.log(error);
            });
    };
    
    
    const completeTaskRequest = (task_id) => {

        axios.post(`${url}/api/complete_task`, {
            id: id,
            task_id: task_id,
            data: tg.initData,
        })
            .then(response => {

                getTasksRequest();

            })
            .catch(error => {
                console.log(error);
            });
        
    }


    const claimTaskRequest = (task_id) => {

        axios.post(`${url}/api/claim_task`, {
            id: id,
            task_id: task_id,
            data: tg.initData,
        })
            .then(response => {

                setPower(response.data);

                getTasksRequest();

            })
            .catch(error => {
                console.log(error);
            });

    }
    
    
    


    const tasks = [

        {
            id: 1,
            title: 'Join on Twitter',
            reward: 30,
            completed: tasksArray?.find(task => task.id === 1)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 1)?.claimed || false,
        },

        {
            id: 2,
            title: 'Post 4 Stories',
            reward: 60,
            completed: tasksArray?.find(task => task.id === 2)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 2)?.claimed || false,
        },

        {
            id: 4,
            title: 'Invite 3 Friends',
            reward: 80,
            completed: tasksArray?.find(task => task.id === 4)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 4)?.claimed || false,
        },

        {
            id: 3,
            title: 'Post 20 Stories',
            reward: 360,
            completed: tasksArray?.find(task => task.id === 3)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 3)?.claimed || false,
        },

        {
            id: 5,
            title: 'Invite 15 Friends',
            reward: 480,
            completed: tasksArray?.find(task => task.id === 5)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 5)?.claimed || false,
        },

    ]



    









    useEffect(() => {

        getTasksSchemaRequest();

    }, [])


    const joinTwitter = () => {

        window.open('https://x.com', '_blank')
        setTimeout(() => {
            completeTaskRequest(1)
        }, 500)

    }



    return (


        <>
            <div className={`${tasksModal ? 'active' : ''} modal-overlay`} onClick={() => setTasksModal(false)}></div>
            <div className={`${tasksModal ? 'active' : ''} autoheight tasks-wrapper modal-wrapper`}>


                <div className={'story-container-top'}>


                    <div className={'header-left'}>
                        <h3 className={'white'}>
                            TASKS
                        </h3>
                        <div className={'header-left-bottom'}>
                            <p className={'white'}>
                                Increase GO POWER by completing tasks
                            </p>
                        </div>
                    </div>

                </div>


                {/*<div className={'tasks-img-container'}>
                <img className={'tasks-img'} src={hi}/>
            </div>*/}


                <div className={'tasks-container'} style={{marginTop: '12px'}}>

                    {
                        tasks.map((task, index) =>

                            <div className={'task-item'} key={index}>


                                <div className={'task-item-left'}>

                                    <h3 className={'bold black'}>
                                        {task.title}
                                    </h3>
                                    <p className={'black'}>
                                        +{task.reward} TonGO
                                    </p>
                                </div>


                                <button
                                    className={`${(!task.completed && task.id !== 1) || task.claimed ? 'disabled' : ''} small flexible btn black`}
                                    onTouchEnd={() => {
                                        task.completed ?
                                            claimTaskRequest(task.id)
                                            :
                                            task.id === 1 ?
                                                joinTwitter()
                                                :
                                                completeTaskRequest(task.id);
                                        haptic.impactOccurred('light');
                                    }}
                                >
                                    {
                                        task.claimed ?
                                            'Claimed'
                                            :
                                            task.completed ?
                                                'Claim'
                                                :
                                                task.id === 1 ?
                                                    'Join'
                                                    :
                                                    'Claim'
                                    }
                                </button>

                            </div>
                        )
                    }

                </div>

                <button
                    className={'btn full white noshadow'}
                    onTouchEnd={() => {
                        setTasksModal(false);
                        haptic.impactOccurred('light');
                    }}
                >
                    CLOSE
                </button>

            </div>
        </>


    );
};

export default Tasks;