import React, {useState} from 'react';
import {first_name, is_premium, tg} from "../pages/Go";
import './Welcome.css';
import hi_illust from "../assets/hi_illust.svg";
import welcome1 from "../assets/welcome1.png";
import welcome2 from "../assets/welcome2.png";
import welcome3 from "../assets/welcome3.png";

const dots = [1,2,3];

const Welcome = ({welcomeModal, setWelcomeModal, isSubscribed}) => {

    const [welcomeStep, setWelcomeStep] = useState(0);

    return (
        <div className={`${welcomeModal ? 'active' : ''} welcome-wrapper modal-wrapper`}>

            <img
                src={
                    welcomeStep === 0 ?
                        welcome1
                        :
                        welcomeStep === 1 ?
                            welcome2
                            :
                            welcome3
                }
                alt="hello"
            />

            <div className={'welcome-top-dots-container'}>
                {
                    dots.map((item, index) =>
                        <div className={`${welcomeStep === index ? 'active' : ''} welcome-top-dot`} key={index}>

                        </div>
                    )
                }
            </div>


            <div className={'welcome-center-container'}>

                <h1 className={'lactos purple'}>
                    {
                        welcomeStep === 0 ?
                            'HELLO, DEAR FRIEND!'
                            :
                            welcomeStep === 1 ?
                                is_premium ?
                                    'PREMIUM DETECTED!'
                                    :
                                    'PREMIUM MISSING.'
                                :
                                isSubscribed ?
                                    'WELCOME TO COMMUNITY!'
                                    :
                                    'SUBSCRIBE TO CHANNEL!'
                    }

                </h1>

                <p className={'white'}>
                    {
                        welcomeStep === 0 ?
                            <>
                                Nice to meet you, {first_name}.
                                <br/>
                                <span className={'purple'}>Welcome to TonGO!</span>
                            </>
                            :
                            welcomeStep === 1 ?
                                is_premium ?
                                    <>
                                        Here is <span className={'purple'}> 400GO </span> for your premium status!
                                    </>
                                    :
                                    <>
                                        To <span className={'purple'}>post stories</span> you have to have premium
                                        account.
                                    </>
                                :
                                isSubscribed !== 0 ?
                                    <>
                                        Channel subscription verified. Here is your <span
                                        className={'purple'}> 120GO</span>!
                                    </>
                                    :
                                    <>
                                        Subscribe to our <span className={'purple'}>Telegram Channel</span> to continue!
                                    </>
                    }
                </p>

            </div>


            <button
                className={`bottom full btn ${welcomeStep === 2 && isSubscribed === 0 ? 'purple' : 'white'}`}
                onClick={() => {
                    if (welcomeStep === 2) {
                        if (isSubscribed !== 0) {
                            setWelcomeModal(false);
                        } else {
                            tg.openTelegramLink('https://t.me/tongo')
                        }
                    } else {
                        setWelcomeStep(prev => prev + 1);
                    }
                }}
            >
                {
                    welcomeStep === 0 ?
                        "GO AHEAD"
                        :
                        welcomeStep === 1 ?
                            is_premium ?
                                "COOL!"
                                :
                                "THAT'S OK"
                            :
                            isSubscribed !== 0 ?
                                "Continue"
                                :
                                "SUBSCRIBE"
                }
            </button>

        </div>
    );
};

export default Welcome;